import { FETCH_ERROR_VARIABLES, FETCH_START_VARIABLES, FETCH_SUCCESS_VARIABLES, 
	SET_VARIABLES } from "constants/ActionTypes";
import {INIT_URL} from "constants/ActionTypes";
  
const INIT_STATE = {
	initURL: "",
	variables: null,
	error: "",
	loading: false,
	message: "",
	response: null,
};
  
export default (state = INIT_STATE, action) => {
    
	switch (action.type) {
  
	case INIT_URL: {
		return {...state, initURL: action.payload};
	}
	case SET_VARIABLES: {
		return {
			...state,
			variables: action.payload,
		};
	}
	case FETCH_START_VARIABLES: {
		return {...state, error: "", message: "", loading: true, response: null, action: ""};
	}
	case FETCH_SUCCESS_VARIABLES: {
		return {...state, error: "", message: "", loading: false, response: null, action: ""};
	}
	case FETCH_ERROR_VARIABLES: {
		return {...state, loading: false, error: action.payload, message: "", response: false, action: ""};
	}
	default:
		return state;
	}
};