import React from "react";
export const Nav00DataSource = {
	wrapper: { className: "header0 home-page-wrapper" },
	page: { className: "home-page" },
	logo: {
		className: "header0-logo",
		children: "https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg",
	},
	Menu: {
		className: "header0-menu",
		children: [
			{
				name: "item0",
				className: "header0-item",
				children: {
					href: "#",
					children: [{ children: "导航一", name: "text" }],
				},
				subItem: [
					{
						name: "sub0",
						className: "item-sub",
						children: {
							className: "item-sub-item",
							children: [
								{
									name: "image0",
									className: "item-image",
									children:
                    "https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg",
								},
								{
									name: "title",
									className: "item-title",
									children: "Ant Design",
								},
								{
									name: "content",
									className: "item-content",
									children: "企业级 UI 设计体系",
								},
							],
						},
					},
					{
						name: "sub1",
						className: "item-sub",
						children: {
							className: "item-sub-item",
							children: [
								{
									name: "image0",
									className: "item-image",
									children:
                    "https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg",
								},
								{
									name: "title",
									className: "item-title",
									children: "Ant Design",
								},
								{
									name: "content",
									className: "item-content",
									children: "企业级 UI 设计体系",
								},
							],
						},
					},
				],
			},
			{
				name: "item1",
				className: "header0-item",
				children: {
					href: "#",
					children: [{ children: "导航二", name: "text" }],
				},
			},
			{
				name: "item2",
				className: "header0-item",
				children: {
					href: "#",
					children: [{ children: "导航三", name: "text" }],
				},
			},
			{
				name: "item3",
				className: "header0-item",
				children: {
					href: "#",
					children: [{ children: "导航四", name: "text" }],
				},
			},
		],
	},
	mobileMenu: { className: "header0-mobile-menu" },
};
export const Banner50DataSource = {
	wrapper: { className: "home-page-wrapper banner5" },
	page: { className: "home-page banner5-page" },
	childWrapper: {
		className: "banner5-title-wrapper",
		children: [
			{ name: "title", children: "NovaCita", className: "banner5-title" },
			{
				name: "explain",
				className: "banner5-explain",
				children: "Reserva rápido y fácil",
			},
			{
				name: "content",
				className: "banner5-content",
				children: "Crea una cuenta y empieza a buscar especialistas",
			},
		],
	},
	image: {
		className: "banner5-image",
		children:
      "assets/images/logo.png",
	},
};
export const Content00DataSource = {
	wrapper: { className: "home-page-wrapper content0-wrapper" },
	page: { className: "home-page content0" },
	OverPack: { playScale: 0.3, className: "" },
	titleWrapper: {
		className: "title-wrapper",
		children: [{ name: "title", children: "¿Cómo Funciona?" }],
	},
	childWrapper: {
		className: "content0-block-wrapper",
		children: [
			{
				name: "block0",
				className: "content0-block",
				md: 8,
				xs: 24,
				children: {
					className: "content0-block-item",
					children: [
						{
							name: "image",
							className: "content0-block-icon",
							children:
                "https://api.novacita.com/registro.png",
						},
						{
							name: "title",
							className: "content0-block-title",
							children: "Registro",
						},
						{ name: "content", children: "Crea una cuenta gratis" },
					],
				},
			},
			{
				name: "block1",
				className: "content0-block",
				md: 8,
				xs: 24,
				children: {
					className: "content0-block-item",
					children: [
						{
							name: "image",
							className: "content0-block-icon",
							children:
                "https://api.novacita.com/busqueda.png",
						},
						{
							name: "title",
							className: "content0-block-title",
							children: "Búsqueda",
						},
						{
							name: "content",
							children: "Encuentra al especialista",
						},
					],
				},
			},
			{
				name: "block2",
				className: "content0-block",
				md: 8,
				xs: 24,
				children: {
					className: "content0-block-item",
					children: [
						{
							name: "image",
							className: "content0-block-icon",
							children:
                "https://api.novacita.com/agenda.png",
						},
						{
							name: "title",
							className: "content0-block-title",
							children: "Reserva",
						},
						{
							name: "content",
							children: "Selecciona la fecha y horario",
						},
					],
				},
			},
		],
	},
};
export const Content50DataSource = {
	wrapper: { className: "home-page-wrapper content5-wrapper" },
	page: { className: "home-page content5" },
	OverPack: { playScale: 0.3, className: "" },
	titleWrapper: {
		className: "title-wrapper",
		children: [
			{ name: "title", children: "Especialidades", className: "title-h1" },
			{
				name: "content",
				className: "title-content",
				children: "Especialidades más buscadas",
			},
		],
	},
	block: {
		className: "content5-img-wrapper",
		gutter: 16,
		children: [
			{
				name: "block0",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/5234501/pexels-photo-5234501.jpeg",
					},
					content: { children: "Cardiología" },
				},
			},
			{
				name: "block1",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/5069430/pexels-photo-5069430.jpeg",
					},
					content: { children: "Dermatología" },
				},
			},
			{
				name: "block3",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/7089035/pexels-photo-7089035.jpeg",
					},
					content: { children: "Ginecología" },
				},
			},
			{
				name: "block4",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/3779705/pexels-photo-3779705.jpeg",
					},
					content: { children: "Odontología" },
				},
			},
			{
				name: "block5",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/5765829/pexels-photo-5765829.jpeg",
					},
					content: { children: "Oftalmología" },
				},
			},
			{
				name: "block6",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/8460047/pexels-photo-8460047.jpeg",
					},
					content: { children: "Pediatría" },
				},
			},
			{
				name: "block7",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/5699456/pexels-photo-5699456.jpeg",
					},
					content: { children: "Psicología" },
				},
			},      
			{
				name: "block71",
				className: "block",
				md: 6,
				xs: 24,
				children: {
					wrapper: { className: "content5-block-content" },
					img: {
						children:
              "https://images.pexels.com/photos/5452223/pexels-photo-5452223.jpeg",
					},
					content: { children: "Traumatología" },
				},
			},
		],
	},
};
export const Content30DataSource = {
	wrapper: { className: "home-page-wrapper content3-wrapper" },
	page: { className: "home-page content3 kqoh3ewg31t-editor_css" },
	OverPack: { playScale: 0.3 },
	titleWrapper: {
		className: "title-wrapper",
		children: [
			{
				name: "title",
				children: "¿Por qué elegirnos?",
				className: "title-h1",
			},
			{
				name: "content",
				className: "title-content",
				children: "Funcionalidades gratuitas para pacientes y doctores",
			},
		],
	},
	block: {
		className: "content3-block-wrapper",
		children: [
			{
				name: "block0",
				className: "content3-block",
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: "content3-icon",
						children:
              "https://api.novacita.com/verificados.png",
					},
					textWrapper: { className: "content3-text" },
					title: { className: "content3-title", children: "Médicos Verificados" },
					content: {
						className: "content3-content",
						children:
              "Si necesitas un doctor lo puedes buscar por especialidad, nombre o ciudad",
					},
				},
			},
			{
				name: "block1",
				className: "content3-block",
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: "content3-icon",
						children:
              "https://api.novacita.com/horarios.png",
					},
					textWrapper: { className: "content3-text" },
					title: { className: "content3-title", children: "Horarios Flexibles" },
					content: {
						className: "content3-content",
						children:
              "Revisa la disponibilidad y programa la reserva en el día y hora adecuada para tí",
					},
				},
			},
			{
				name: "block2",
				className: "content3-block",
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: "content3-icon",
						children:
              "https://api.novacita.com/recordatorios.png",
					},
					textWrapper: { className: "content3-text" },
					title: { className: "content3-title", children: "Recordatorios" },
					content: {
						className: "content3-content",
						children:
              "Recibe recordatorios al teléfono y correo registrado",
					},
				},
			},
			{
				name: "block3",
				className: "content3-block",
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: "content3-icon",
						children:
              "https://api.novacita.com/historial.png",
					},
					textWrapper: { className: "content3-text" },
					title: { className: "content3-title", children: "Historial" },
					content: {
						className: "content3-content",
						children:
              "Registro de historial de consultas médicas",
					},
				},
			},
			{
				name: "block4",
				className: "content3-block",
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: "content3-icon",
						children:
              "https://api.novacita.com/prescripciones.png",
					},
					textWrapper: { className: "content3-text" },
					title: { className: "content3-title", children: "Prescripciones" },
					content: {
						className: "content3-content",
						children:
              "Revisa las prescripciones recetadas por el doctor el día de la consulta",
					},
				},
			},
			{
				name: "block5",
				className: "content3-block",
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: "content3-icon",
						children:
              "https://api.novacita.com/aplicaciones.png",
					},
					textWrapper: { className: "content3-text" },
					title: { className: "content3-title", children: "Aplicaciones" },
					content: {
						className: "content3-content",
						children:
              "Encuentranos en Android y IOS",
					},
				},
			},
		],
	},
};
export const Content110DataSource = {
	OverPack: {
		className: "home-page-wrapper content11-wrapper",
		//playScale: 0.3,
	},
	titleWrapper: {
		className: "title-wrapper",
		children: [
			{
				name: "image",
				children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
				className: "title-image",
			},
			{ name: "title", children: "Únete a NovaCita hoy mismo", className: "title-h1" },
			{
				name: "content",
				children:
          "Cuida de tú salud y de tus familiares",
				className: "title-content",
			},
			{
				name: "content2",
				children: "",
				className: "title-content",
			},
		],
	},
	button: {
		className: "",
		children: { a: { className: "button", href: "#", children: "Registrarse" } },
	},
};
export const Feature60DataSource = {
	wrapper: { className: "home-page-wrapper feature6-wrapper" },
	OverPack: { className: "home-page feature6", playScale: 0.3 },
	Carousel: {
		className: "feature6-content",
		dots: false,
		wrapper: { className: "feature6-content-wrapper" },
		titleWrapper: {
			className: "feature6-title-wrapper",
			barWrapper: {
				className: "feature6-title-bar-wrapper",
				children: { className: "feature6-title-bar" },
			},
			title: { className: "feature6-title" },
		},
		children: [
			{
				title: { className: "feature6-title-text", children: "Pacientes" },
				className: "feature6-item",
				name: "block0",
				children: [
					{
						md: 8,
						xs: 24,
						className: "feature6-number-wrapper",
						name: "child0",
						number: {
							className: "feature6-number",
							unit: { className: "feature6-unit", children: "" },
							toText: true,
							children: "116",
						},
						children: { className: "feature6-text", children: "Doctores disponibles" },
					},
					{
						md: 8,
						xs: 24,
						className: "feature6-number-wrapper",
						name: "child2",
						number: {
							className: "feature6-number",
							unit: { className: "feature6-unit", children: "" },
							toText: true,
							children: null,
						},
						children: { className: "feature6-text", children: null },
					},
					{
						md: 8,
						xs: 24,
						className: "feature6-number-wrapper",
						name: "child1",
						number: {
							className: "feature6-number",
							unit: { className: "feature6-unit", children: "" },
							toText: true,
							children: "1090",
						},
						children: { className: "feature6-text", children: "Reservas realizadas" },
					},
          
				],
			},
			{
				title: { className: "feature6-title-text", children: "Doctores" },
				className: "feature6-item",
				name: "block1",
				children: [
					{
						md: 8,
						xs: 24,
						name: "child0",
						className: "feature6-number-wrapper",
						number: {
							className: "feature6-number",
							unit: { className: "feature6-unit", children: "" },
							toText: true,
							children: "1000",
						},
						children: { className: "feature6-text", children: "Pacientes registrados" },
					},
					{
						md: 8,
						xs: 24,
						name: "child1",
						className: "feature6-number-wrapper",
						number: {
							className: "feature6-number",
							unit: { className: "feature6-unit", children: "" },
							toText: true,
							children: null,
						},
						children: { className: "feature6-text", children: null },
					},
					{
						md: 8,
						xs: 24,
						name: "child2",
						className: "feature6-number-wrapper",
						number: {
							className: "feature6-number",
							unit: { className: "feature6-unit", children: "" },
							toText: true,
							children: "1000",
						},
						children: { className: "feature6-text", children: "Recetas emitidas" },
					},
				],
			},
		],
	},
};
export const Feature50DataSource = {
	wrapper: { className: "home-page-wrapper content7-wrapper" },
	page: { className: "home-page content7" },
	OverPack: {},
	titleWrapper: {
		className: "title-wrapper",
		children: [
			{
				name: "title",
				children: "Aplicaciones Móviles",
				className: "title-h1",
			},
			{ name: "content", children: "Encuentranos en las principales tiendas" },
		],
	},
	tabsWrapper: { className: "content7-tabs-wrapper" },
	block: {
		children: [
			{
				name: "block0",
				tag: {
					className: "content7-tag",
					text: { children: "GOOGLE PLAY(ANDROID)", className: "content7-tag-name" },
					icon: { children: "mobile" },
				},
				content: {
					className: "content7-content",
					text: {
						className: "content7-text",
						md: 14,
						xs: 24,
						children: (
							<span>
								<h3>Caracteristica 1</h3>
								<p>
                 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</p>
								<br />
								<h3>Caracteristica 2</h3>
								<p>
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</p>
								<br />
								<h3>
                  Caracteristica 3
								</h3>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx。
							</span>
						),
					},
					img: {
						className: "content7-img",
						children:
              "https://bondzu.com/img/playStoreIcon.png",
						md: 10,
						xs: 24,
					},
				},
			},
			{
				name: "block1",
				tag: {
					className: "content7-tag",
					icon: { children: "tablet" },
					text: { className: "content7-tag-name", children: "APP STORE(IOS)" },
				},
				content: {
					className: "content7-content",
					text: {
						className: "content7-text",
						md: 14,
						xs: 24,
						children: (
							<span>
								<h3>Caracteristica 1</h3>
								<p>
                 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</p>
								<br />
								<h3>Caracteristica 2</h3>
								<p>
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</p>
								<br />
								<h3>
                  Caracteristica 3
								</h3>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx。
							</span>
						),
					},
					img: {
						className: "content7-img",
						md: 10,
						xs: 24,
						children:
              "https://www.messengerpeople.com/wp-content/uploads/2019/08/apple-app-store-badge-es.png",
					},
				},
			},
			{
				name: "block2",
				tag: {
					className: "content7-tag",
					text: { children: "WEB", className: "content7-tag-name" },
					icon: { children: "laptop" },
				},
				content: {
					className: "content7-content",
					text: {
						className: "content7-text",
						md: 14,
						xs: 24,
						children: (
							<span>
								<h3>Caracteristica 1</h3>
								<p>
                 xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</p>
								<br />
								<h3>Caracteristica 2</h3>
								<p>
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
								</p>
								<br />
								<h3>
                  Caracteristica 3
								</h3>xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx。
							</span>
						),
					},
					img: {
						className: "content7-img",
						md: 10,
						xs: 24,
						children:
              "https://w7.pngwing.com/pngs/770/94/png-transparent-web-browser-browser-wars-internet-explorer-netscape-google-chrome-internet-explorer-microsoft-google-chrome-browser-wars.png",
					},
				},
			},
		],
	},
};
export const Feature20DataSource = {
	wrapper: { className: "home-page-wrapper content2-wrapper" },
	OverPack: { className: "home-page content2", playScale: 0.3 },
	imgWrapper: { className: "content2-img", md: 10, xs: 24 },
	img: {
		children: "https://zos.alipayobjects.com/rmsportal/tvQTfCupGUFKSfQ.png",
	},
	textWrapper: { className: "content2-text", md: 14, xs: 24 },
	title: { className: "content2-title", children: "分布式中间件" },
	content: {
		className: "content2-content",
		children:
      "金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。",
	},
};
export const Feature40DataSource = {
	wrapper: { className: "home-page-wrapper content6-wrapper" },
	OverPack: { className: "home-page content6" },
	textWrapper: { className: "content6-text", xs: 24, md: 10 },
	titleWrapper: {
		className: "title-wrapper",
		children: [
			{
				name: "title",
				children: "蚂蚁金融云提供专业的服务",
				className: "title-h1",
			},
			{
				name: "content",
				className: "title-content",
				children: "基于阿里云计算强大的基础资源",
			},
		],
	},
	img: {
		children: "https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png",
		className: "content6-img",
		xs: 24,
		md: 14,
	},
	block: {
		children: [
			{
				name: "block0",
				img: {
					children:
            "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
					className: "content6-icon",
				},
				title: { className: "content6-title", children: "技术" },
				content: {
					className: "content6-content",
					children:
            "丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。",
				},
			},
			{
				name: "block1",
				img: {
					className: "content6-icon",
					children:
            "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
				},
				title: { className: "content6-title", children: "融合" },
				content: {
					className: "content6-content",
					children:
            "解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。",
				},
			},
			{
				name: "block2",
				img: {
					className: "content6-icon",
					children:
            "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
				},
				title: { className: "content6-title", children: "开发" },
				content: {
					className: "content6-content",
					children:
            "符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。",
				},
			},
		],
	},
};
export const Teams10DataSource = {
	wrapper: { className: "home-page-wrapper teams1-wrapper" },
	page: { className: "home-page teams1" },
	OverPack: { playScale: 0.3, className: "" },
	titleWrapper: {
		className: "title-wrapper",
		children: [{ name: "title", children: "Testimonios" }],
	},
	block: {
		className: "block-wrapper",
		children: [
			{
				name: "block0",
				className: "block",
				md: 8,
				xs: 24,
				titleWrapper: {
					children: [
						{
							name: "image",
							className: "teams1-image",
							children:
                "https://api.novacita.com/testimonios.png",
						},
						{ name: "title", className: "teams1-title", children: "Maria A." },
						{
							name: "content",
							className: "teams1-job",
							children: "Paciente",
						},
						{
							name: "content1",
							className: "teams1-content",
							children:
                "Prueba comentario test",
						},
					],
				},
			},
			{
				name: "block1",
				className: "block",
				md: 8,
				xs: 24,
				titleWrapper: {
					children: [
						{
							name: "image",
							className: "teams1-image",
							children:
                "https://api.novacita.com/testimonios.png",
						},
						{ name: "title", className: "teams1-title", children: "Marco M." },
						{
							name: "content",
							className: "teams1-job",
							children: "Médico Cardiologo",
						},
						{
							name: "content1",
							className: "teams1-content",
							children:
                "Prueba comentario",
						},
					],
				},
			},
			{
				name: "block2",
				className: "block",
				md: 8,
				xs: 24,
				titleWrapper: {
					children: [
						{
							name: "image",
							className: "teams1-image",
							children:
                "https://api.novacita.com/testimonios.png",
						},
						{ name: "title", className: "teams1-title", children: "Juana P." },
						{
							name: "content",
							className: "teams1-job",
							children: "Paciente",
						},
						{
							name: "content1",
							className: "teams1-content",
							children:
                "Prueba comentario",
						},
					],
				},
			},
		],
	},
};
export const Footer10DataSource = {
	wrapper: { className: "home-page-wrapper footer1-wrapper" },
	OverPack: { className: "footer1", playScale: 0.2 },
	block: {
		className: "home-page kqoh4lidnc-editor_css",
		gutter: 0,
		children: [
			{
				name: "block0",
				xs: 24,
				md: 6,
				className: "block",
				title: {
					className: "logo",
					children:
            "https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg",
				},
				childWrapper: {
					className: "slogan",
					children: [
						{
							name: "content0",
							children: "",
						},
					],
				},
			},
			{
				name: "block1",
				xs: 24,
				md: 6,
				className: "block",
				title: { children: "NovaCita" },
				childWrapper: {
					children: [
						{ name: "https://landing.novacita.com", href: "https://landing.novacita.com", children: "Acerca de", target: "_blank"},
						{ name: "https://landing.novacita.com/soporte", href: "https://landing.novacita.com/soporte", children: "FAQ", target: "_blank" },
						{ name: "https://landing.novacita.com/contacto", href: "https://landing.novacita.com/contacto", children: "Contacto", target: "_blank" },
					],
				},
			},
			{
				name: "block2",
				xs: 24,
				md: 6,
				className: "block",
				title: { children: "Servicios" },
				childWrapper: {
					children: [
						{ href: "https://admin.novacita.com", name: "link0", children: "Doctores", target: "_blank" },
						{ href: "https://novacita.com", name: "link1", children: "Pacientes", target: "_blank" },
					],
				},
			},
			{
				name: "block3",
				xs: 24,
				md: 6,
				className: "block",
				title: { children: "Recursos" },
				childWrapper: {
					children: [
						{ href: "https://landing.novacita.com", name: "link0", children: "Centro de ayuda", target: "_blank" },
						{ href: "https://landing.novacita.com/terminos-y-condiciones", name: "link1", children: "Términos y Condiciones", target: "_blank" },
						{ href: "https://landing.novacita.com/politicas", name: "link2", children: "Política de Privacidad", target: "_blank" },
						{ href: "#", name: "link3", children: "Contactos de soporte: "},
						{ href: "#", name: "link4", children: "soporte@novacita.com"},
						{ href: "#", name: "link5", children: "0982333123"},
					],
				},
			},
		],
	},
	copyrightWrapper: { className: "copyright-wrapper" },
	copyrightPage: { className: "home-page" },
	copyright: {
		className: "copyright",
		children: (
			<span>
        @2022 <a href="https://novacita.com">NovaCitaSoft S.A</a> Todos los derechos
        Reservados
			</span>
		),
	},
};
