import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE} from "constants/ActionTypes";
import {FETCH_ERROR_LOGIN, FETCH_ERROR_REGISTER, FETCH_START_LOGIN, FETCH_START_REGISTER, FETCH_SUCCESS_LOGIN, FETCH_SUCCESS_REGISTER, RESET_ACTION_COMMON, SET_ACTION_REGISTER, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "../../constants/ActionTypes";

const INIT_STATE = {
	error: "",
	loading: false,
	message: "",
	navCollapsed: true,
	width: window.innerWidth,
	pathname: "/",
	error_login: "",
	loading_login: false,
	message_login: "",
	error_register: "",
	loading_register: false,
	message_register: "",
	action_register: ""
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
	case "@@router/LOCATION_CHANGE": {
		return {
			...state,
			pathname: action.payload.location.pathname,
			navCollapsed: false
		};
	}
	case WINDOW_WIDTH:
		return {
			...state,
			width: action.width,
		};
	case TOGGLE_COLLAPSED_NAV: {
		return {
			...state,
			navCollapsed: action.navCollapsed
		};
	}
	case FETCH_START: {
		return {...state, error: "", message: "", loading: true};
	}
	case FETCH_SUCCESS: {
		return {...state, error: "", message: "", loading: false};
	}
	case SHOW_MESSAGE: {
		return {...state, error: "", message: action.payload, loading: false};
	}
	case FETCH_ERROR: {
		return {...state, loading: false, error: action.payload, message: ""};
	}
	case HIDE_MESSAGE: {
		return {...state, loading: false, error: "", message: ""};
	}
	case FETCH_START_LOGIN: {
		return {...state, error_login: "", message_login: "", loading_login: true};
	}
	case FETCH_SUCCESS_LOGIN: {
		return {...state, error_login: "", message_login: "", loading_login: false};
	}
	case FETCH_ERROR_LOGIN: {
		return {...state, loading_login: false, error_login: action.payload, message_login: ""};
	}
	case FETCH_START_REGISTER: {
		return {...state, error_register: "", message_register: "", loading_register: true};
	}
	case FETCH_SUCCESS_REGISTER: {
		return {...state, error_register: "", message_register: "", loading_register: false};
	}
	case FETCH_ERROR_REGISTER: {
		return {...state, loading_register: false, error_register: action.payload, message_register: ""};
	}
	case SET_ACTION_REGISTER: {
		return {
			...state,
			action_register: action.payload,
		};
	}
	case RESET_ACTION_COMMON: {
		return {...state, loading: false, error: "", message: "", action_register: "", error_register: "", message_register: ""};
	}
	default:
		return state;
	}
};
