import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	SIGNOUT_USER_SUCCESS,
	USER_DATA,
	USER_TOKEN_SET,
	FETCH_START_LOGIN,
	FETCH_ERROR_LOGIN,
	FETCH_SUCCESS_LOGIN,
	FETCH_START_REGISTER,
	FETCH_ERROR_REGISTER,
	FETCH_SUCCESS_REGISTER,
	SET_ACTION_REGISTER,
	FETCH_ACTION,
	FETCH_ACTIVE,
	OPTIONS_REQUIRED_LOGIN
} from "../../constants/ActionTypes";
import axios from "util/Api";

export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};

export const userSignUp = ({email, password, name}) => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post("auth/register", {
			email: email,
			password: password,
			name: name
		}
		).then(({data}) => {
			if (data.result) {
				localStorage.setItem("token", JSON.stringify(data.token.access_token));
				axios.defaults.headers.common["authorization"] = "Bearer " + data.token.access_token;
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: USER_DATA, payload: data.user});
				dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        
			} else {
				dispatch({type: FETCH_ERROR, payload: "Network Error"});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error.message});
		});
	};
};

export const userSignIn = ({email, password}) => {
	return (dispatch) => {
		dispatch({type: FETCH_START_LOGIN});
		axios.post("login", {
			email: email,
			password: password,
		}
		).then(({data}) => {
			if (data.result) {
				localStorage.setItem("token", JSON.stringify(data.token.access_token));
				localStorage.setItem("as_user", JSON.stringify(data.user));
				axios.defaults.headers.common["Authorization"] = "Bearer " + data.token.access_token;
				dispatch({type: FETCH_SUCCESS_LOGIN});
				dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
				dispatch({type: USER_DATA, payload: data.user});
				dispatch({type: OPTIONS_REQUIRED_LOGIN, payload: false});
			} else {
				dispatch({type: FETCH_ERROR_LOGIN, payload: data.error});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_LOGIN, payload: error.message});
		});
	};
};

export const getUser = () => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post("auth/me",
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: USER_DATA, payload: data.user});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error.message});
		});
	};
};



export const userSignOut = () => {

	return (dispatch) => {
		dispatch({type: FETCH_START});

		axios.post("logout").then(({data}) => {
			if (data.result) {
				localStorage.removeItem("token");
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: SIGNOUT_USER_SUCCESS});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.error});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error.message});
		});
	};
};

export const userSignUpParentPacient = (data, action) =>  {
	return (dispatch) => {
		dispatch({type: FETCH_START_REGISTER});
		axios.post("pacients", data
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS_REGISTER});
				dispatch({type: SET_ACTION_REGISTER, payload: action});
			} else {
				dispatch({type: FETCH_ERROR_REGISTER, payload: data.message});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR_REGISTER, payload: error});
		});
	};
};


export const getActivationPacient = (token, action) => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.get("/pacients/verify/"+token,
		).then(({data}) => {
			if (data.result) {
				dispatch({type: FETCH_SUCCESS});
				dispatch({type: FETCH_ACTION, payload: action});
				dispatch({type: FETCH_ACTIVE, payload: true});
			} else {
				dispatch({type: FETCH_ERROR, payload: data.message});
				dispatch({type: FETCH_ACTIVE, payload: false});
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error});
			dispatch({type: FETCH_ACTIVE, payload: false});
		});
	};
};